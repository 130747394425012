import '../styles/globals.scss'
import { useRouter, Router } from 'next/router'
import Header from './components/header'
import Footer from './components/footer'
import Starscape from './components/starscape'
import { AnimatePresence } from 'framer-motion'

function MyPortfolio({ Component, pageProps }) {
  const router = useRouter()
  const routeChange = () => {
    // Temporary fix to avoid flash of unstyled content
    // during route transitions. Keep an eye on this
    // issue and remove this code when resolved:
    // https://github.com/vercel/next.js/issues/17464

    const tempFix = () => {
      const allStyleElems = document.querySelectorAll('style[media="x"]');
      allStyleElems.forEach((elem) => {
        elem.removeAttribute("media");
      });
    };
    tempFix();
  };

  Router.events.on("routeChangeComplete", routeChange );
  Router.events.on("routeChangeStart", routeChange );
  return (
    <>
      <Header/>
      <Starscape/>
      <AnimatePresence mode="wait">
      <Component {...pageProps} key={router.pathname} />
      </AnimatePresence>
      <Footer/>
    </>
  )
}

export default MyPortfolio
