import React from 'react'
import gsap from 'gsap'

export default function Starscape({ 
    densityRatio = 0.1,
    sizeLimit = 5,
    defaultAlpha = 0.5,
    scaleLimit = 2,
    proximityRatio = 0.1 }) {

    const canvasRef = React.useRef(null)
    const contextRef = React.useRef(null)
    const starsRef = React.useRef(null)
    const vminRef = React.useRef(null)
    const scaleMapperRef = React.useRef(null)
    const alphaMapperRef = React.useRef(null)

    React.useEffect(() => {
        contextRef.current = canvasRef.current.getContext('2d')
        loadStars()
        gsap.ticker.add(renderStars)
        gsap.ticker.fps(24)

        window.addEventListener('resize', loadStars)
        document.addEventListener('pointermove', updateStars)
        return () => {
            window.removeEventListener('resize', loadStars)
            document.removeEventListener('pointermove', updateStars)
            gsap.ticker.remove(renderStars)
        }
    }, [])

    // const drawMoon = () => {
    //     contextRef.current.beginPath();
    //     //contextRef.current.moveTo(canvasRef.current.width * 0.8, 150);
    //     var startX = canvasRef.current.width * 0.8
    //     var startY = 150
    //     contextRef.current.arc(startX, startY, 60, 0.2* Math.PI, 1.5* Math.PI);
    //     //contextRef.current.moveTo(20, 20);
    //     contextRef.current.bezierCurveTo(startX + 110, 105, 700, startY + 225, startX + 46, 188);
    //     contextRef.current.strokeStyle = "yellow";
    //     contextRef.current.stroke();    
    // }
  
    const loadStars = () => {
        vminRef.current = Math.min(window.innerHeight, window.innerWidth)
        const STAR_COUNT = Math.floor(vminRef.current * densityRatio)
        scaleMapperRef.current = gsap.utils.mapRange(
            1,
            vminRef.current * proximityRatio,
            scaleLimit,
            1
        );
        alphaMapperRef.current = gsap.utils.mapRange(
            0,
            vminRef.current * proximityRatio,
            1,
            defaultAlpha
        );
        canvasRef.current.width = window.innerWidth
        canvasRef.current.height = window.innerHeight
        starsRef.current = new Array(STAR_COUNT).fill().map(() => ({
            x: gsap.utils.random(0, window.innerWidth, 1),
            y: gsap.utils.random(0, window.innerHeight, 1),
            size: gsap.utils.random(1, sizeLimit, 1),
            scale: 1,
            alpha: gsap.utils.random(0.1, defaultAlpha, 0.1),
        }))
    }
    const renderStars = () => {
        contextRef.current.clearRect(
            0,
            0,
            canvasRef.current.width,
            canvasRef.current.height
          )
          starsRef.current.forEach(star => {
            contextRef.current.fillStyle = `hsla(0, 100%, 100%, ${star.alpha})`
            contextRef.current.beginPath()
            contextRef.current.arc(
              star.x,
              star.y,
              (star.size / 2) * star.scale,
              0,
              Math.PI * 2
            )
            contextRef.current.fill()
          })
    }
    const updateStars = ({ x, y }) => {
        starsRef.current.forEach(STAR => {
            const DISTANCE = Math.sqrt(Math.pow(STAR.x - x, 2) + Math.pow(STAR.y - y, 2));
            gsap.to(STAR, {
                scale: scaleMapperRef.current(
                    Math.min(DISTANCE, vminRef.current * proximityRatio)
                ),
                alpha: alphaMapperRef.current(
                    Math.min(DISTANCE, vminRef.current * proximityRatio)
                )
            });
        })
    };
  

    return (
        <div>
            
            <canvas ref={canvasRef} className={'star-bg'} />
            <div className={'moon-container'}>
                <div className={'moon'}>
                    <div className={'crater--0'}></div>
                    <div className={'crater--1'}></div>
                    <div className={'crater--2'}></div>
                    <div className={'crater--3'}></div>
                    <div className={'crater--4'}></div>
                    <div className={'crater--5'}></div>
                    <div className={'crater--6'}></div>
                    <div className={'crater--7'}></div> 
                </div>
            </div>
        </div>
    )
}