import React from 'react'
import Head from 'next/head'
import NavItem from './nav-item'
import styles from '/styles/Home.module.scss'


export default function Header() {
    const [navActive, setNavActive] = React.useState(null);
    const [activeIdx, setActiveIdx] = React.useState(-1);
    const _menu_items = [
        { text: "home", href: "/" },
        { text: "about", href: "/about-me" },
        { text: "expertise", href: "/expertise" },
        { text: "experience", href: "/experience" },
        { text: "contact", href: "/contact" },
      ];
    return (
        <div className={styles.container}>
            <Head>
            <title>Hello World! Kennice Fung - Software Engineer</title>
            <meta name="description" content="Hello World! This is Kennice from Hong Kong!" />
            <link rel="icon" href="/portfolio.ico" />
            </Head>

            <header>
                <nav className={`nav`}>
                    <div
                        onClick={() => setNavActive(!navActive)}
                        className={`nav__menu-bar`}
                        >
                    <div></div>
                    <div></div>
                    <div></div>
                    </div>
                    <div className={`${navActive ? "active" : ""} nav__menu-list`}>
                    {_menu_items.map((menu, idx) => (
                        <div
                        onClick={() => {
                            setActiveIdx(idx);
                            setNavActive(false);
                        }}
                        key={menu.text}
                        >
                        <NavItem active={activeIdx === idx} {...menu} />
                        </div>
                    ))}
                    </div>
                </nav>
            </header>
        </div>
    )
}