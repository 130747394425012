import Link from "next/link"
import {useRouter} from "next/router"
const NavItem = ({ text, href, active }) => {
  const router = useRouter()
  const handleOnClick = (e) =>{
    router.push(href)
  } 
  return (
    <span>
      <a onClick={handleOnClick}
        className={`nav__item ${
          active ? "active" : ""
        }`}
      >
        {text}
      </a>
    </span>
  );
};

export default NavItem